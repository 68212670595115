<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="headercontent.title" />
        </v-col>
        <v-col md="8" v-if="!loadingData">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="message"
                  outlined
                  label="Message"
                  class="mr-btm-minus"
                  :rules="messageRules"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <div class="mb-1">Select Emotion For Reflection Video Only</div>
                <v-select
                  v-model="connectedTo"
                  :items="allEmotions.results"
                  label="Emotion"
                  item-text="name"
                  item-value="id"
                  outlined
                  :rules="emotionRules"
                ></v-select>
              </v-col>

              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                  :loading="loading"
                >
                  {{ headercontent.title }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col md="8" v-if="loadingData">
          <v-progress-circular
            indeterminate
            color="gray"
            class="mr-3"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddPrompt",
  components: { PageHeader },
  data: () => ({
    valid: true,
    message: "",
    connectedTo: "",
    headercontent: {
      title: "Add Prompter",
    },
    messageRules: [(v) => !!v || "Message is required"],
    emotionRules: [(v) => !!v || "Emotion is required"],
    loading: false,
    loadingData: true,
  }),
  methods: {
    async validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const data = {
          message: this.message,
          connectedTo: this.connectedTo,
        };
        if (this.id) {
          await this.updatePrompter({ id: this.id, data });
        } else {
          await this.createPrompter(data);
        }
        this.loading = false;
      }
    },

    ...mapActions([
      "fetchEmotions",
      "createPrompter",
      "updatePrompter",
      "getPrompter",
    ]),
  },
  computed: {
    ...mapGetters(["allLoadingCurd", "allEmotions", "currentPrompter"]),
  },

  async created() {
    this.loadingData = true;
    await this.fetchEmotions({ sort: 'name' });
    if (this.$route.params.id) {
      await this.getPrompter(this.$route.params.id);
      this.message = this.currentPrompter.message;
      this.connectedTo = this.currentPrompter.connectedTo;
      this.id = this.currentPrompter._id;
      this.headercontent.title = "Edit Prompter";
    }
    this.loadingData = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 300px;
  min-height: 50px;
}
</style>
